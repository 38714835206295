<template>
  <div>
    <div class="d-flex align-items-center justify-content-end">
      <b-button
        variant="gradient-primary"
        @click="submit"
      >
        <i
          class="fad fa-cloud-download-alt"
          style="color: #fff"
        />
        บันทึกข้อมูล
      </b-button>
    </div>
    <b-row class="match-height mt-1">
      <b-col md="7">
        <b-card>
          <h3>
            <i class="fad fa-university" /> จัดการเลขบัญชีธนาคารลูกค้าโอนเข้า
          </h3>
          <b-tabs pills>
            <b-tab
              title="บัญชีธนาคารที่ 1"
              active
            >
              <b-form-group>
                <v-select
                  v-model="agent.dip_bank_code"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="banklist"
                  :reduce="name => name.bankcode"
                />
              </b-form-group>
              <b-form-group
                label="เลขที่บัญชี"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label1"
                  v-model="agent.dip_bank_accno"
                  placeholder="เลขที่บัญชี"
                />
              </b-form-group>

              <b-form-group
                label="ชื่อบัญชี"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="agent.dip_bank_accname"
                  placeholder="ชื่อบัญชี"
                />
              </b-form-group>
              <b-form-group
                label="Prefix"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="agent.prefix"
                  placeholder="Prefix"
                />
              </b-form-group>
              <b-form-group
                label="เว็บไซต์"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="agent.site"
                  placeholder="เว็บไซต์"
                />
              </b-form-group>
              <b-form-group
                label="ชื่อเว็บไซต์"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="agent.site_name"
                  placeholder="ชื่อเว็บไซต์"
                />
              </b-form-group>
            </b-tab>
            <b-tab title="บัญชีธนาคารที่ 2">
              <div>
                <b-form-group>
                  <v-select
                    v-model="agent.dip_bank2_code"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="banklist2"
                    :reduce="name => name.bankcode"
                  />
                </b-form-group>
                <b-form-group
                  label="เลขที่บัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label1"
                    v-model="agent.dip_bank2_accno"
                    placeholder="เลขที่บัญชี"
                  />
                </b-form-group>

                <b-form-group
                  label="ชื่อบัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="agent.dip_bank2_accname"
                    placeholder="ชื่อบัญชี"
                  />
                </b-form-group>
              </div></b-tab>
          </b-tabs>
        </b-card>
      </b-col>
      <b-col md="5">
        <div class="row">
          <div class="col-md-6">
            <div class="g-status success">
              <i
                class="fas fa-wallet fa-2x mb-1"
                style="color:#fff"
              />
              <h6
                class="text-white"
                style="font-weight: 700"
              >
                เปิด-ปิด หน้าฝาก
              </h6>
              <b-form-checkbox
                v-model="agent.status_dip"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="g-status error">
              <i
                class="fas fa-hands-usd fa-2x mb-1"
                style="color:#fff"
              />
              <h6
                class="text-white"
                style="font-weight: 700"
              >
                เปิด-ปิด หน้าถอน
              </h6>
              <b-form-checkbox
                v-model="agent.status_wit"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="g-status info">
              <i
                class="fas fa-sack-dollar fa-2x mb-1"
                style="color:#fff"
              />
              <h6
                class="text-white"
                style="font-weight: 700"
              >
                เปิด-ปิด เติมเครดิตออโต้
              </h6>
              <b-form-checkbox
                v-model="agent.status_auto_dip"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="g-status warning">
              <i
                class="fas fa-ban fa-2x mb-1"
                style="color:#fff"
              />
              <h6
                class="text-white"
                style="font-weight: 700"
              >
                เปิด-ปิด ตัดเครดิตออโต้
              </h6>
              <b-form-checkbox
                v-model="agent.status_auto_wit"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="g-status warning">
              <i
                class="fas fa-ban fa-2x mb-1"
                style="color:#fff"
              />
              <h6
                class="text-white"
                style="font-weight: 700"
              >
                เปิด-ปิด SMS เติมเครดิตออโต้
              </h6>
              <b-form-checkbox
                v-model="agent.dep_sms"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="g-status warning">
              <i
                class="fas fa-ban fa-2x mb-1"
                style="color:#fff"
              />
              <h6
                class="text-white"
                style="font-weight: 700"
              >
                เปิด-ปิด SCB Connect
              </h6>
              <b-form-checkbox
                v-model="agent.dep_connect"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-12">
            <div class="g-status secondary">
              <i
                class="fas fa-power-off fa-2x mb-1"
                style="color:#fff"
              />

              <h6
                class="text-white"
                style="font-weight: 700"
              >
                เปิด-ปิด Maintenance
              </h6>
              <b-form-checkbox
                v-model="agent.maintenance"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </b-col>

      <b-col md="6">
        <b-card>
          <h3><i class="fad fa-gifts" /> ราคาคืนสลากและแบ่งเปอร์เซ็นต์ให้กับซับเอเย่นต์</h3>
          <b-form-group
            label="ราคาคืนสลากของเอเย่นต์"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.agent_refund"
              type="number"
              placeholder="ราคาคืน/ใบ"
            />
          </b-form-group>
          <b-form-group
            label="เปอร์เซ็นต์ที่แบ่งให้กับซัพเอเย่นต์ (หน่วยเป็นบาท)"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.subagent_percent"
              type="number"
              placeholder="เปอร์เซ็นต์/ใบ"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h3><i class="fad fa-gifts" /> ราคาสลากที่ขายให้กับเอเย่นต์</h3>
          <b-form-group
            label="ราคาสลากที่ขายให้กับเอเย่นต์ 1 เล่ม"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.agent_price1"
              type="number"
              placeholder="ราคาสลาก/ใบ"
            />
          </b-form-group>
          <b-form-group
            label="ราคาสลากที่ขายให้กับเอเย่นต์มากกว่า 3 เล่ม"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.agent_price3"
              type="number"
              placeholder="ราคาสลาก/ใบ"
            />
          </b-form-group>
          <b-form-group
            label="ราคาสลากที่ขายให้กับเอเย่นต์มากกว่า 5 เล่ม"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.agent_price5"
              type="number"
              placeholder="ราคาสลาก/ใบ"
            />
          </b-form-group>
          <b-form-group
            label="ราคาสลากที่ขายให้กับเอเย่นต์ 10 เล่มขึ้นไป"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.agent_price10"
              type="number"
              placeholder="ราคาสลาก/ใบ"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h3>
            <i class="fad fa-donate" /> ยอดถอนเครดิตสูงสุด
            <small>(โดยไม่ต้อง approve)</small>
          </h3>
          <b-form-group
            label="ถอนเครดิตสูงสุด"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.withdraw_approve"
              type="number"
              placeholder="ถอนเครดิตสูงสุด"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h3><i class="fab fa-ethereum" /> ยอดถอนต่อครั้ง</h3>
          <b-form-group
            label="ถอนเครดิตต่ำสุด"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.withdraw_min"
              type="number"
              placeholder="ถอนเครดิตต่ำสุด"
            />
          </b-form-group>
          <b-form-group
            label="ถอนเครดิตสูงสุด"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.withdraw_max"
              type="number"
              placeholder="ถอนเครดิตสูงสุด"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h3><i class="fad fa-wallet" /> จัดการ truemoney ขาเข้า</h3>
          <b-form-group
            label="เบอร์ทรูมันนี่"
            label-for="customDelimiter"
          >
            <cleave
              id="customDelimiter"
              v-model="agent.truemoney_acc"
              class="form-control"
              :raw="false"
              :options="options.customDelimiter"
              placeholder=""
            />
          </b-form-group>
          <b-form-group
            label="ชื่อบัญชี"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.truemoney_name"
              placeholder="ชื่อบัญชี"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h3><i class="fad fa-wallet" /> จัดการ Line</h3>
          <b-form-group
            label="ไลน์ Contact หน้าเว็บ wallet"
            label-for="customDelimiter"
          >
            <b-form-input
              id="linecontact"
              v-model="agent.linecontact"
              placeholder="ไลน์ Contact หน้าเว็บ wallet"
            />
          </b-form-group>
          <b-form-group
            label="ไลน์ Token ฝาก"
            label-for="linetoken_dip"
          >
            <b-form-input
              id="linetoken_dip"
              v-model="agent.linetoken_dip"
              placeholder="ไลน์ Token ฝาก"
            />
          </b-form-group>
          <b-form-group
            label="ไลน์ Token ถอน"
            label-for="linetoken_wit"
          >
            <b-form-input
              id="linetoken_wit"
              v-model="agent.linetoken_wit"
              placeholder="ไลน์ Token ฝาก"
            />
          </b-form-group>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card>
          <h3><i class="fad fa-megaphone" /> ข้อความประกาศ</h3>
          <b-form-group
            label="เพิ่มข้อความประกาศ"
            label-for="anounce"
          >
            <b-form-textarea
              id="anounce"
              v-model="agent.announce"
              rows="5"
            />
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTab,
  BFormCheckbox,
  BTabs,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    Cleave,
    BFormGroup,
    vSelect,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTab,
    BTabs,
    BFormTextarea,
  },
  data() {
    return {
      banklist: [],
      banklist2: [{ bankcode: '004', name: 'ธนาคารกสิกรไทย' }],
      agent: {},
      selected: { title: '' },
      option: [{ title: 'Square' }],
      options: {
        customDelimiter: {
          delimiters: ['-', '-', '-'],
          blocks: [3, 3, 4],
          uppercase: true,
        },
      },
    }
  },
  mounted() {
    this.Get_bank()
    this.getAgent()
  },
  methods: {
    Get_bank() {
      this.$http
        .get('https://api.sabaideelotto.com/api/bank/list')
        .then(response => {
          this.banklist = response.data
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    getAgent() {
      this.$http
        .get('https://api.sabaideelotto.com/api/agent/show')
        .then(response => {
          this.agent = response.data
          this.switch(response.data)
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    switch(data) {
      if (data.status_dip === '1') {
        this.agent.status_dip = true
      }
      if (data.status_wit === '1') {
        this.agent.status_wit = true
      }
      if (data.status_auto_dip === '1') {
        this.agent.status_auto_dip = true
      }
      if (data.status_auto_wit === '1') {
        this.agent.status_auto_wit = true
      }
      if (data.dep_sms === '1' || data.dep_sms === 1) {
        this.agent.dep_sms = true
      }
      if (data.dep_connect === '1' || data.dep_connect === 1) {
        this.agent.dep_connect = true
      }
      if (data.maintenance === '1') {
        this.agent.maintenance = true
      }
    },
    submit() {
      if (this.agent.id) {
        this.$http
          .post('https://api.sabaideelotto.com/api/agent/update', this.agent)
          .then(() => {
            this.getAgent()
            this.Success('แก้ไขข้อมูล สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      } else {
        this.$http
          .post('https://api.sabaideelotto.com/api/agent/store', this.agent)
          .then(() => {
            this.getAgent()
            this.Success('เพิ่มข้อมูล สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.g-status {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  border-radius: 0.25rem;
  padding: 2.35rem;
  margin-bottom: 1rem;
}
.g-status.success {
  background: linear-gradient(to right, #3dc92a, #00770a);
}
.g-status.error {
  background: linear-gradient(to right, #ff5b5b, #c70000);
}
.g-status.warning {
  background: linear-gradient(to right, #ffb800, #ff8a00);
}
.g-status.info {
  background: linear-gradient(to right, #00b0ff, #0078ff);
}
.g-status.secondary {
  background: linear-gradient(to right, #797979, #424547);
}

i {
  color: #2e5d92;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
